 @import "~bootstrap-sass/assets/stylesheets/bootstrap";

.content-wrapper { background-color: #fff; }

.skin-green-light{
  .wrapper, .main-sidebar, .left-side { background-color: #fff; }
  .sidebar-menu > li.header { color: #fbaf3f; font-weight: 600; font-size: 15px; background-color: #fff }
  .sidebar-menu{
    li{
      a{
        .fa,.fas,.far{
          width: 20px;
        }
      }
    }
  }
}

.main-header {
  .header-toggle{
    color: #ffffff;
    &:hover{
      color: #ffffff;
    }
  }
  .sidebar-toggle:before{
    content: none;
  }
}

.main-footer{
  border-top: none;
}

.support-logo{
  padding-right: 20px;
}

h1, h2, h3, h4, h5, legend, .main-sidebar i {
    color: #3c8476;
    text-transform: uppercase;
    .widget-user-header & { text-transform: none; color: inherit; }
}

legend { border-color: #fbaf3f; }

h1 { font-weight: 600; }

.btn, .btn-sm{
  border: none;
  border-radius: 0;
  font-weight: bold;
  &.btn-primary{
    background-color: #fbaf3f;
    &:active:focus{
      background-color: #3c8476;
    }
    &:active:hover{
      background-color: #3c8476;
    }
  }
}


a, .btn-link {
  color:#4bbca7;
  &:hover, &:active, &:focus { color: #3c8476; }
}

.info-box {  border: 1px solid #d2d6de; }

.box {
  border: 1px solid #d2d6de;
  border-top-width: 3px;
  &-header.with-border { border-bottom: 1px solid #d2d6de; }
  &-header &-title { color: #3c8476; }
}

.inner h4 { color: inherit; }

.user-header .badge { background-color: #FFF; color: #000; }

.parsley-required {color:#b94a48;}
.bg-red .modal-title,
.box-danger .box-title {
  color: #fff;
}
.required:after { content:" *";color:#b94a48; }
.hidden-input {visibility:hidden;width:0;padding: 0;border:none;}

.dt-button,
.dt-button:focus{
  display: inline-block;
  background-color: #4bbca7;
  border-color: #4bbca7;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 15px;
  margin-bottom: 12px;
  -webkit-transition: background-color 0.4s, border-color 0.4s;
  -o-transition: background-color 0.4s, border-color 0.4s;
  -moz-transition: background-color 0.4s, border-color 0.4s;
  transition: background-color 0.4s, border-color 0.4s;
}
.dt-button:hover,
.dt-button:active{
  background-color: #3c8476;
  border-color: #3c8476;
  -webkit-transition: background-color 0.4s, border-color 0.4s;
  -o-transition: background-color 0.4s, border-color 0.4s;
  -moz-transition: background-color 0.4s, border-color 0.4s;
  transition: background-color 0.4s, border-color 0.4s;
}

.dt-buttons, .dataTables_length{
  display: inline-block;
  padding-right: 10px;
}

.dataTables_filter{
  display: inline-block;
  float: right;
}

.table-bordered{
  border: 1px solid #ddd !important;
}

.dataTables_paginate {
  float: right;
  & > span > .paginate_button, & > .paginate_button{
    border: 1px solid;
    padding: 5px 10px;
    margin: 2px;
    &.current{
      background-color: #3FB096;
      color: #fff;
    }
    &:not(.disabled){
      &:hover{
        cursor: pointer;
        background-color: #3FB096;
        color: #fff;
        text-decoration: none;
      }
    }
    &.disabled{
      display: none;
      //border: none;
      //&:hover{
      //  cursor: not-allowed;
      //  text-decoration: none;
      //  color: #efefef;
      //}
    }
  }
}

hr.elearning-divider{
 border: 0.5px solid #61BBA7 !important;
}

.elearning-box{
 border: none !important;
 box-shadow: none;
 color: #454545;
 font-size: 15px;
 background-color: #f9fafc;

 &-title{
   color:  #26313f;
   font-size: 25px;
   font-weight: 400;
   line-height: 15px;
   margin-bottom: 20px;
 }

 &-subtitle{
   margin-top: 12px;
   text-transform: uppercase;
   font-size: 18px;
   font-weight: 700;
   color: #61BBA7;
   position: relative;
   top: 20px;
 }

 &-stats{
   li{
     line-height: 2.0;
   }
 }

 &-actions{
   margin-top: 4px;
   a{
     margin-left: 4px;
   }
 }

 .list-unstyled{
   li{
     padding: 5px 0 5px 0;
   }
 }

 .select2-selection--multiple{
   -webkit-box-shadow: none !important;
   -moz-box-shadow: none !important;
   box-shadow: none !important;
   border: none !important;
   border-radius: 0 !important;
   padding-top: 0 !important;
   padding-right: 1px !important;
   background: transparent !important;
   .select2-selection__choice{
     background-color: #F2F2F2 !important;
     border-color: #F2F2F2 !important;
     border-radius: 0 !important;
     font-weight: 500;
     @media (min-width: 480px) {
       width: 47% !important;
     }
     @media (max-width: 479px) {
       width: 94% !important;
     }
     padding: 4px 0 4px 4px !important;
     .select2-selection__choice__remove{
       margin: 0 5px 0 5px !important;
       color: #fbaf3f !important;
     }
   }
   .select2-search--inline{
     @media (min-width: 480px) {
       width: 47% !important;
     }
     @media (max-width: 479px) {
       width: 94% !important;
     }
     padding-bottom: 5px;
     .select2-search__field{
       position: relative;
       top: 5px;
       left: 6px;
       width: 100% !important;
       height: 30px !important;
       border: 1px solid #CCCCCC;
       padding-left: 34px !important;
       background: rgba(255, 255, 255, 100) url("/images/action/action_icon_search.png") no-repeat scroll 0 center / 28px 28px !important;
     }
   }
 }

 .question-content{
   color: #454545;
   font-size: 18px;
   font-weight: 400;
 }
}

.blur_quiz{
 -webkit-filter: blur(10px);
 -moz-filter: blur(10px);
 -o-filter: blur(10px);
 -ms-filter: blur(10px);
 filter: blur(10px);
}

.elearning-section {
 padding-top: 20px;

 input[type="search"] {
   padding-top: 2px;
   background: rgba(255, 255, 255, 100) url("/images/action/action_icon_search.png") no-repeat scroll 100% center / 29px 29px !important;
   border: none;

   &:focus {
     outline: none;
   }
 }
}

 .quiz-step{
   &:not(.member-quiz-step){
     border: 1px solid #61BBA7;
     border-top-right-radius: 10px;
     border-bottom-right-radius: 10px;
     border-bottom-left-radius: 10px;
   }
   padding: 30px;
   .quiz_title{

   }
   .quiz_step_index{
     font-size: 15px;
     font-weight: 300;
     color: #787878;
     text-transform: none;
   }
   .quiz_sub_title{
     font-size: 20px;
     padding-top: 20px;
     padding-bottom: 10px;
   }
   .question-container{
     margin-bottom: 30px;
     input[type='radio']:after{
       top:0;
       left:-2px;
     }

   }
   li.option{
     label{
       font-weight:400 !important;
     }
   }
 }

 .step_question{
   label, strong, span{
     font-size: 17px;
   }
   .tf-statement{
     font-size:17px;
     font-weight:500;
   }
 }


 .elearning-center-col{
   float: none;
   margin-left: auto;
   margin-right: auto;
 }
 .vp-player-layout{
   width: inherit;
 }
 .quiz-result-title, .module-edit-title{
   text-transform: none;
   font-size: 28px;
   font-weight: 700;
   color: #3c8476;
 }

 .quiz-result-block{
   margin-top: 50px;
   div {
     padding-top: 20px;
   }
   ul {
     li{
       font-size:18px;
     }
     padding-bottom: 40px;
   }
 }

 fieldset.spacer{
   margin-top: 40px;
   margin-bottom: 30px;
 }

 .btn-tab{
   border-radius: 0;
   //border-color: #714c64;
   margin-bottom: 0;
   margin-right: 6px;
   padding: auto;
   height: 50px;
   width: 120px;
   font-size: 18px;
   font-weight: 600;
   text-align: center;
   border-bottom: 0;
   &:hover{
     background-color: #61BBA7;
     border-color: #61BBA7;
     color: #f3f3f3;
     -webkit-transition: color 0.4s;
     -o-transition: color 0.4s;
     -moz-transition: color 0.4s;
     transition: color 0.4s;
   }
   &:focus{
     outline: none;
   }
 }

 .tab-elearning{
   margin-bottom: 1px !important;
   position: relative;
   top: 2px;
   font-weight: 900;
   font-size: 18px;
   text-transform: uppercase;
   &-active{
     background-color: #ffffff;
     border: 1px solid #61BBA7;
     border-bottom: none;
     position: relative;
     top: 1.2px;
     color: #61BBA7;
     border-top-left-radius: 10px;
     border-top-right-radius: 10px;
   }
   &-inactive{
     background-color: #ffffff;
     border-color: #ffffff;
     color: #7a7a7a;
     position: relative;
     top: 1.2px;
     border-bottom: 1px solid #61BBA7;
     border-top-left-radius: 10px;
     border-top-right-radius: 10px;
   }
   &-new{
     background-color: #61BBA7;
     border-color: #61BBA7;
     color: #ffffff;
     position: relative;
     top: 1.2px;
     border-bottom: 1px solid #61BBA7;
     border-top-left-radius: 10px;
     border-top-right-radius: 10px;
   }
 }

 .steps-tabs{
   margin-top: 25px;
 }
 .module-steps{
   padding-left:15px;
   padding-right: 15px;

   .step-section{
     padding: 30px;
     margin-bottom: 30px;
     background-color: #ffffff;
     .step-info {
       padding-left: 0;
     }
     .step-video{
       padding-right: 0;
     }
   }
 }

 //iframe, video{
 //  //position: absolute;
 //  //top: 0;
 //  //left: 0;
 //  width: 100%;
 //  height: 100%;
 //  //border: 0;
 //}

 .resp-container{
   overflow: hidden;
   padding-top: 28%;
   position: relative;
   height: 300px;
   iframe {
     border: 0;
     //height: 100%;
     left: 0;
     position: absolute;
     top: 0;
     width: 100%;
   }
 }

 .btn-download-cert{
   padding: 0;
 }

 input[type='radio']:after {
   font-family: "Font Awesome 5 Free";
   font-weight: 900;
   padding-top: 1px;
   text-align: center;
   width: 18px;
   height: 18px;
   border-radius: 18px;
   top: -2px;
   left: -3px;
   position: relative;
   background-color: #fff;
   color: rgba(255,255,255,0);
   content: '\f00d';
   display: inline-block;
   visibility: visible;
   border: 1px solid #61BBA7;
   outline-style: none;
   -webkit-transition: color 0.4s linear;
   -o-transition: color 0.4s linear;
   -moz-transition: color 0.4s linear;
   transition: color 0.4s linear;
 }

 input[type='radio']:checked:after {
   color: #61BBA7;
   visibility: visible;
   outline-style: none;
   -webkit-transition: color 0.4s linear;
   -o-transition: color 0.4s linear;
   -moz-transition: color 0.4s linear;
   transition: color 0.4s linear;
 }

 input[type='checkbox']:after {
   font-family: "Font Awesome 5 Free";
   font-weight: 900;
   width: 16px;
   height: 16px;
   position: relative;
   top: -2px;
   left: -2px;
   background-color: #fff;
   color: rgba(255,255,255,0);
   content: "\f00c";
   display: inline-block;
   visibility: visible;
   border: 1px solid #61BBA7;
   outline-style: none;
   -webkit-transition: color 0.4s linear;
   -o-transition: color 0.4s linear;
   -moz-transition: color 0.4s linear;
   transition: color 0.4s linear;
 }

 input[type='checkbox']:checked:after {
   color: #61BBA7;
   visibility: visible;
   outline-style: none;
   -webkit-transition: color 0.4s linear;
   -o-transition: color 0.4s linear;
   -moz-transition: color 0.4s linear;
   transition: color 0.4s linear;
 }

 .sidebar-footer{
   height: 60px;
   width: inherit;
   background-color: #307263;
   color: #ffffff;
   position: fixed;
   bottom: 0;

   .fill-section{
     display: block;
     height: 100%;
     width: 100%;
     padding: 15px 0 0 40px;
     &:hover{
       text-decoration: none;
     }
     .powered-by-guild{
       padding-left: 5px;
       font-size: 12px;
       font-style: italic;
       color: #ffffff;
       .guild{
         font-weight: 900;
       }
     }
     .guild_logo{
       width: 36px;
       display: inline;
     }
   }
 }

 .sidebar{
   margin-bottom: 60px;
 }

 @media (max-width: 991px){
     .main-header .navbar-custom-menu a{
       color: #4bbca7;
     }
     .main-header .navbar-custom-menu a:hover{
       color: #3c8476;
     }
 }
 @media (max-width: 767px){
     .skin-green-light .main-header .navbar .dropdown-menu li a{
       color: #4bbca7;
     }
     .skin-green-light .main-header .navbar .dropdown-menu li a:hover{
       color: #3c8476;
       background: none !important;
     }
 }

 .content-header .page-title {
   text-align: center;
   font-size: 30px !important;
   font-weight: 600;
   text-transform: none;
   color: #3c8476;

   small {
     padding: 0;
     text-align: center;
     display: block;
     font-weight: normal;
     margin: 10px 0;
   }

 }

 .timeline-inverse{
   &::before{
     background: none;
     border-left: 2px dashed #ececec;
   }
   .time-label{
     span{
       border: 2px dashed #ececec;
       border-radius: 0;
       white-space: nowrap;
       div{
         display: inline-block;
       }
     }
   }
   li{
     .timeline-item{
       background: transparent;
       border-radius: 0;
     }
   }
 }

 .btn-circle.btn-xl {
   width: 100px;
   height: 100px;
   border-radius: 50%;
   border-color: #4bbca7;
   background-color: #4bbca7;
   color: #ffffff;
   margin-bottom: 10px;
   -webkit-transition: background-color 0.4s, border-color 0.4s;
   -o-transition: background-color 0.4s, border-color 0.4s;
   -moz-transition: background-color 0.4s, border-color 0.4s;
   transition: background-color 0.4s, border-color 0.4s;
 }
 .btn-circle.btn-xl:hover,
 .btn-circle.btn-xl:active{
   background-color: #3c8476;
   border-color: #3c8476;
   color: #ffffff;
   outline: none;
   -webkit-transition: background-color 0.4s, border-color 0.4s;
   -o-transition: background-color 0.4s, border-color 0.4s;
   -moz-transition: background-color 0.4s, border-color 0.4s;
   transition: background-color 0.4s, border-color 0.4s;
 }

 .dashboard_icon{
   padding-top: 23.5%
 }

 .dashboard-item{
   color: #444444;
   font-size: 18px;
   text-align: center;
   margin-bottom: 30px;
   white-space: nowrap;
 }

 .row.activity-bar {
   background-color: #3c8476;
   text-align: center;
   text-transform: uppercase;
   font-weight: 800;
   font-size: 18px;
   padding-bottom: 10px;
   padding-top: 10px;

   .info-text{ color: #ffffff;}
   .info-number{ color: #fbaf3f; }
   .info-spacing{ padding: 8px; }
 }

 .bring-to-front {
   z-index: 1;
 }

header nav .navbar-nav .tech-support {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  border: none;
  border-radius: 30px 30px 8px 30px;
  background-color: #ef4370;
  box-shadow: none;
  color: white;
  padding: 8px 10px;
  transition: ease all 0.5s;
  margin-top: 4px;
}

header nav .navbar-nav .tech-support i {
  margin-right: 6px;
}

header nav .navbar-nav .tech-support:hover {
  background-color: #eb144c;
}

.quality-description {
  display: none;
  background: white;
  width: 500px;
  height: 250px;
  position: absolute;
  border: 1px solid black;
  padding: 10px;
}

 .activity-description {
   display: none;
   background: white;
   width: 550px;
   height: 550px;
   position: absolute;
   border: 1px solid black;
   padding: 10px;
   z-index: 1000;
 }